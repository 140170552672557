import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import 'payble-ui/src/main.css';
import App from './App';
import {PaybleUI} from 'payble-ui';
import {api} from './lib/api';

import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {makeErrorInvalidator, makeInvalidator} from 'payble-api-react-query';
import {billerQueryConfig} from 'payble-api-client';

Sentry.init({
  dsn: 'https://f650d498782844ccbbda7683211f7603@o1097373.ingest.sentry.io/6467259',
  integrations: [new Integrations.BrowserTracing()],
  environment: import.meta.env.VITE_STAGE as string,
  tracesSampler: __context => {
    if (window.location.hostname === 'localhost') {
      return 0;
    } else {
      return 1;
    }
  },
});

const queryClient = new QueryClient();
api.successTopic.subscribe(makeInvalidator(queryClient, billerQueryConfig));
api.errorTopic.subscribe(makeErrorInvalidator(queryClient, billerQueryConfig));

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <PaybleUI>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </PaybleUI>
  </React.StrictMode>
);
