import {BillerAppConfig} from 'payble-shared/src/app-config/BillerAppConfig';
import {z} from 'zod';
import {zISODate} from '../schemas';
import {defineHTTPEndpoint} from '../utils';

export const getSession = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'GET /session',
  requestSchema: null,
  responseSchema: z.object({
    email: z.string(),
    adminId: z.string(),
    expiresAt: zISODate,
    appConfig: BillerAppConfig.schema,
  }),
});
