import {z} from 'zod';
import {planResponse, billerInstalmentPlansResponse} from '../schemas';
import {defineHTTPEndpoint} from '../utils';

export const rebalancePlan = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'POST /rebalance-plan',
  requestSchema: z.object({
    instalmentPlanId: z.string(),
    dryRun: z.boolean(),
    disableNotifications: z.boolean(),
  }),
  responseSchema: planResponse,
});

export const getInstalmentPlans = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'GET /instalment-plans',
  queryKey: body => ['instalment-plans', body?.accountId, body?.contactId],
  requestSchema: z.object({
    accountId: z.string().nullable(),
    contactId: z.string().nullable(),
  }),
  responseSchema: billerInstalmentPlansResponse,
});
