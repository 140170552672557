import React from 'react';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {useAPIMutation} from 'lib/api';
import {useToast} from 'payble-ui';
import {CancelModal} from '../components/CancelModal';

type UnarchiveContactFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
  contactId: string;
  refetch: () => void;
};

export const UnarchiveContact: React.FC<UnarchiveContactFormProps> = ({
  disclosure: {isOpen, onClose},
  contactId,
  refetch,
}) => {
  const {toast} = useToast();
  const restoreContactMutation = useAPIMutation('restoreContact', {
    query: {
      onSuccess: () => {
        toast({
          title: 'Successful',
          description: 'Contact has been restored',
          variant: 'success',
        });
        onClose();
        refetch();
      },
      onError: e => {
        toast({
          title: 'Failed to restore contact',
          description: e.message,
          variant: 'destructive',
        });
      },
    },
  });

  return (
    <CancelModal
      headline="Unarchive Contact"
      description="Plans and communication preferences will need to be reinstated for this unarchived contact. Please select a reason from the options below:"
      isOpen={isOpen}
      onClose={onClose}
      onReject={async reason => {
        await restoreContactMutation.mutateAsync({
          contactId,
          reason,
        });
      }}
      reasons={['Customer requested', 'Other']}
    />
  );
};
