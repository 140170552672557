import {useState, useEffect} from 'react';
import {RadioGroup} from '@headlessui/react';
import {useDebouncedCallback} from 'use-debounce';
import {useFormikContext} from 'formik';
import CurrencyInput from 'react-currency-input-field';
import classNames from 'classnames';
import {
  AdminRequestedNotificationInput,
  AdminRequestedNotificationInputType,
  InstalmentFrequency,
  useGetAccountsSearchLazyQuery,
  useSendAdminRequestedNotificationMutation,
} from 'lib/graphql/API';
import {formatToCents, formatToDollars} from 'payble-shared';
import {formatSearchResult} from '../utils/formatSearchResult';
import {Select} from 'components/atoms/Select';
import {MiniSpinner} from 'components/atoms/Spinner';
import {Switch} from 'payble-ui';
import {FormErrorMessage} from '../../components/FormErrorMessage';
import {SmsPreview} from '../components/SmsPreview';
import {Search} from 'components/atoms/Search';
import {useAPIQuery} from 'lib/api';

export type Mode = {
  name: string;
  id: string;
  description: string;
};

export type Form = {
  mode?: AdminRequestedNotificationInputType;
  planId?: string;
  suggestedPlanSetup: boolean;
  instalmentFrequency?: InstalmentFrequency;
  instalmentAmount?: number;
  accountId: string;
  type: string;
};

export const MODES_REQUIRE_ACCOUNT = [
  'createPlan',
  'oneOffPayment',
  'planRequestInvite',
  'cancelPlan',
];

export const MODES_REQUIRE_PLAN = ['cancelPlan'];

export const MODES_ACCOUNT_VALIDATION = ['cancelPlan'];

export const MODES_SUGGESTED_AMOUNT = ['planRequestInvite'];

const PlanSelect = ({
  contactId,
  accountId,
  onChange,
}: {
  contactId: string;
  accountId: string;
  onChange: (v: string) => void;
}) => {
  const {errors} = useFormikContext<{planId: string}>();
  const {data: plans, isPending} = useAPIQuery('getInstalmentPlans', {
    data: {
      accountId: accountId ?? null,
      contactId: contactId ?? null,
    },
  });
  const cancellablePlans = plans?.filter(
    ({status}) => !['completed', 'cancelled'].includes(status)
  );

  return (
    <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
      <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
        <div>
          <label
            htmlFor="link-account"
            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
          >
            Plan
          </label>
        </div>
        <div className="sm:col-span-2">
          {isPending ? (
            <MiniSpinner />
          ) : cancellablePlans?.length ? (
            <Select
              values={cancellablePlans.map(value => ({
                name: `${
                  value.nextInstalmentDueAt?.toFormat('dd MMM yyyy') ??
                  'No payments due'
                }: $${formatToDollars(value.amountPaid)}/$${formatToDollars(
                  value.amount
                )} (${value.status})`,
                id: value.id,
              }))}
              defaultValue={cancellablePlans[0].id}
              onChange={async v => onChange(v)}
            />
          ) : (
            <>No plans available for cancellation</>
          )}
          {errors.planId && <FormErrorMessage message={errors.planId} />}
        </div>
      </div>
    </div>
  );
};

export function SelectMode({
  selectedMode,
  modes,
  contactId,
  showPreview,
  onPreviewUpdated,
  setMode,
}: {
  selectedMode?: Mode;
  modes: Mode[];
  contactId?: string;
  showPreview?: boolean;
  onPreviewUpdated?: (body: string[]) => void;
  setMode: (mode: Mode) => void;
}) {
  const [accountFetch, {data: accountData, loading: accountLoading}] =
    useGetAccountsSearchLazyQuery({variables: {offset: 0}});

  const searchAccounts = useDebouncedCallback(async value => {
    accountFetch({
      variables: {
        search: value,
        first: 10,
      },
    });
  }, 500);

  const {setFieldValue, values, errors, touched, isValid} =
    useFormikContext<Form>();

  const [body, setBody] = useState<string[]>();
  const [sendNotification] = useSendAdminRequestedNotificationMutation();

  useEffect(() => {
    onPreviewUpdated?.(body ?? []);
  }, [body]);

  function previewSms(input: Omit<AdminRequestedNotificationInput, 'dryRun'>) {
    setBody([]);

    sendNotification({
      variables: {
        input: {
          ...input,
          dryRun: true,
        },
      },

      notifyOnNetworkStatusChange: true,
    }).then(result => {
      const body = result.data?.sendAdminRequestedNotification?.body;

      if (body) {
        setBody(body);
      }
    });
  }

  const debouncedInstalmentAmount = useDebouncedCallback(values => {
    showPreview &&
      contactId &&
      previewSms({
        contactId,
        type: values.mode!,
        accountId: values.accountId,
        planId: values.planId,
        instalmentFrequency: values.instalmentFrequency,
        instalmentAmount: values.instalmentAmount,
      });
  }, 500);

  return (
    <RadioGroup
      value={selectedMode}
      onChange={mode => {
        setMode(mode);

        showPreview &&
          contactId &&
          previewSms({
            contactId,
            type: mode.id as AdminRequestedNotificationInputType,
            accountId: values.accountId,
            planId: values.planId,
          });
      }}
    >
      <RadioGroup.Label className="sr-only">Plan Type</RadioGroup.Label>
      <div className="-space-y-px bg-white rounded-md">
        {modes.map((setting, settingIdx) => (
          <RadioGroup.Option
            key={setting.name}
            value={setting}
            className={({checked}) =>
              classNames(
                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                settingIdx === modes.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                checked ? 'z-9 border-blue-200 bg-blue-50' : 'border-gray-200',
                'relative flex cursor-pointer border p-4 focus:outline-none'
              )
            }
          >
            {({active, checked}) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? 'bg-blue-600 border-transparent'
                      : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-blue-600' : '',
                    'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <span className="flex flex-col gap-3 ml-3">
                  <div>
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        checked ? 'text-blue-900' : 'text-gray-900',
                        'block text-sm font-medium'
                      )}
                      id={`mode-${setting.id}`}
                    >
                      {setting.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        checked ? 'text-blue-700' : 'text-gray-500',
                        'block text-sm'
                      )}
                    >
                      {setting.description}
                    </RadioGroup.Description>
                  </div>

                  {showPreview &&
                    checked &&
                    MODES_REQUIRE_ACCOUNT.includes(setting.id) && (
                      <div className="sm:divide-y sm:divide-gray-200">
                        <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <div>
                            <label
                              htmlFor="link-account"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Account
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <Search
                              onSearchInputChange={async searchValue => {
                                await searchAccounts(searchValue);
                              }}
                              onSearchSelected={selected => {
                                setFieldValue('accountId', selected.id, true);
                                setFieldValue('type', selected.type);

                                if (!values.mode) {
                                  return;
                                }

                                contactId &&
                                  previewSms({
                                    contactId,
                                    type: values.mode,
                                    accountId: selected.id,
                                    planId: values.planId,
                                  });
                              }}
                              selectedValue={{
                                id: values.accountId,
                                type: values.type,
                              }}
                              loading={accountLoading}
                              values={formatSearchResult(accountData)}
                            />
                            {errors.accountId && touched.accountId && (
                              <FormErrorMessage message={errors.accountId} />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  {showPreview &&
                    contactId &&
                    checked &&
                    MODES_REQUIRE_PLAN.includes(setting.id) &&
                    values.accountId && (
                      <PlanSelect
                        contactId={contactId}
                        accountId={values.accountId}
                        onChange={planId => {
                          setFieldValue('planId', planId, true);

                          previewSms({
                            contactId,
                            type: values.mode!,
                            accountId: values.accountId,
                            planId,
                          });
                        }}
                      />
                    )}

                  {showPreview &&
                    checked &&
                    contactId &&
                    MODES_SUGGESTED_AMOUNT.includes(setting.id) && (
                      <div className="sm:divide-y sm:divide-gray-200">
                        <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6">
                          <div>
                            <label
                              htmlFor="link-account"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Provide suggested amount
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <Switch
                              id="send-welcome-sms"
                              onCheckedChange={checked => {
                                setFieldValue('suggestedPlanSetup', checked);
                                if (!checked) {
                                  setFieldValue(
                                    'instalmentFrequency',
                                    undefined
                                  );
                                  setFieldValue('instalmentAmount', undefined);
                                  previewSms({
                                    contactId,
                                    type: values.mode!,
                                    accountId: values.accountId,
                                    planId: values.planId,
                                    instalmentFrequency: undefined,
                                    instalmentAmount: undefined,
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                  {showPreview &&
                    checked &&
                    contactId &&
                    MODES_SUGGESTED_AMOUNT.includes(setting.id) &&
                    values.suggestedPlanSetup && (
                      <>
                        <div className="sm:divide-y sm:divide-gray-200">
                          <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div>
                              <label
                                htmlFor="link-account"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                              >
                                Frequency
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <Select
                                defaultValue="weekly"
                                values={[
                                  {
                                    id: 'weekly',
                                    name: 'Weekly',
                                  },
                                  {
                                    id: 'fortnightly',
                                    name: 'Fortnightly',
                                  },
                                  {
                                    id: 'monthly',
                                    name: 'Monthly',
                                  },
                                ]}
                                onChange={async value => {
                                  setFieldValue('instalmentFrequency', value);
                                  previewSms({
                                    contactId,
                                    type: values.mode!,
                                    accountId: values.accountId,
                                    planId: values.planId,
                                    instalmentFrequency:
                                      value as InstalmentFrequency,
                                    instalmentAmount: values.instalmentAmount,
                                  });
                                }}
                              />

                              {errors.instalmentFrequency &&
                                touched.instalmentFrequency && (
                                  <FormErrorMessage
                                    message={errors.instalmentFrequency}
                                  />
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="sm:divide-y sm:divide-gray-200">
                          <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div>
                              <label
                                htmlFor="instalmentAmount"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                              >
                                Instalment Amount
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <CurrencyInput
                                name="amount"
                                id="amount"
                                className="block w-full border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="Amount"
                                prefix="$"
                                decimalsLimit={2}
                                onValueChange={value => {
                                  const formattedValue = formatToCents(
                                    Number(value)
                                  );
                                  setFieldValue(
                                    'instalmentAmount',
                                    value ? formattedValue : ''
                                  );

                                  debouncedInstalmentAmount({
                                    ...values,
                                    instalmentAmount: formattedValue,
                                  });
                                }}
                              />

                              {errors.instalmentAmount &&
                                touched.instalmentAmount && (
                                  <FormErrorMessage
                                    message={errors.instalmentAmount}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  {showPreview && isValid && checked && contactId && (
                    <SmsPreview
                      messages={
                        body?.length
                          ? [
                              {
                                key: contactId,
                                text: body,
                                sent: false,
                              },
                            ]
                          : []
                      }
                    />
                  )}
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
