import {z} from 'zod';
import {UnexpectedError} from '../errs';

const BillerAppConfigSchema = z.object({
  stage: z.string(),
  biller: z.object({
    billerId: z.string().uuid(),
    name: z.string(),
    slug: z.string(),
  }),
  region: z.enum(['AU', 'NZ']),
});

export class BillerAppConfig {
  static get schema() {
    return BillerAppConfigSchema.transform(
      data => new BillerAppConfig(data)
    ).or(z.instanceof(BillerAppConfig));
  }
  static maybeFromJSON(data: unknown) {
    try {
      return BillerAppConfig.fromJSON(
        data as z.input<typeof BillerAppConfigSchema>
      );
    } catch {
      return null;
    }
  }

  static fromJSON(data: z.input<typeof BillerAppConfigSchema>) {
    try {
      return new BillerAppConfig(BillerAppConfigSchema.parse(data));
    } catch (error) {
      throw UnexpectedError.wrap(error, undefined, {
        cause: 'Could not parse site config data.',
      });
    }
  }

  get region() {
    return this.props.region;
  }

  get biller() {
    return this.props.biller;
  }

  toJSON(): z.output<typeof BillerAppConfigSchema> {
    return this.props;
  }

  constructor(private props: z.output<typeof BillerAppConfigSchema>) {}
}
