import React, {useEffect} from 'react';
import {RadioGroup} from '@headlessui/react';
import classNames from 'classnames';
import {InstalmentPlan} from '../components/InstalmentPlan';
import NumberFormat from 'react-number-format';
import {
  CalendarIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import {Loading} from 'components/atoms/Loading';
import {TooltipMessage} from 'components/organisms/TooltipMessage';
import {formatToDollars} from 'payble-shared/src/core';
import {useAPIQuery} from 'lib/api';

type InstalmentPlansProps =
  | {
      contactId: string;
      accountId?: never;
    }
  | {
      contactId?: never;
      accountId: string;
    };

export const InstalmentPlans: React.FC<InstalmentPlansProps> = ({
  contactId,
  accountId,
}) => {
  const [focusedPlanId, setFocusedPlanId] = React.useState<string | null>(null);

  const {data: plans, isPending} = useAPIQuery('getInstalmentPlans', {
    data: {
      accountId: accountId ?? null,
      contactId: contactId ?? null,
    },
  });

  useEffect(() => {
    if (plans?.length) {
      setFocusedPlanId(plans[0].id);
    }
  }, [plans]);

  if (isPending) {
    return <Loading />;
  }

  if (!plans?.length) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <CalendarIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No payment plans
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            All payment plans created for this{' '}
            {accountId ? 'account' : 'contact'} will display here.
          </p>
        </div>
      </div>
    );
  }

  const activePlan = plans.find(({id}) => id === focusedPlanId);

  return (
    <div className="mt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-4">
      <div className="space-y-6 lg:col-start-1 lg:col-span-2">
        <section>
          <div className="bg-white shadow sm:rounded-lg">
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <section aria-labelledby="plan-heading">
                <RadioGroup
                  value={activePlan}
                  onChange={c => {
                    setFocusedPlanId(c.id);
                  }}
                >
                  <div className="relative bg-white rounded-md -space-y-px">
                    {plans?.map((instalmentPlans, instalmentPlanIdx) => {
                      const showCancelTooltip =
                        instalmentPlans.cancelledReason !== '';
                      return (
                        <RadioGroup.Option
                          key={instalmentPlans.id}
                          value={instalmentPlans}
                          className={({checked}) =>
                            classNames(
                              instalmentPlanIdx === 0
                                ? 'rounded-tl-md rounded-tr-md'
                                : '',
                              plans && instalmentPlanIdx === plans.length - 1
                                ? 'rounded-bl-md rounded-br-md'
                                : '',
                              checked
                                ? 'border-blue-400 z-10'
                                : 'border-gray-200',
                              'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none'
                            )
                          }
                        >
                          {({active, checked}) => (
                            <>
                              <div className="flex items-center text-sm">
                                <span
                                  className={classNames(
                                    checked
                                      ? 'bg-blue-600 border-transparent'
                                      : 'bg-white border-gray-300',
                                    active
                                      ? 'ring-2 ring-offset-2 ring-blue-500'
                                      : '',
                                    'h-4 w-4 rounded-full border flex items-center justify-center'
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>

                                <RadioGroup.Label
                                  as="span"
                                  className={classNames(
                                    checked ? 'text-blue-900' : 'text-gray-900',
                                    'ml-3 font-medium uppercase'
                                  )}
                                >
                                  {instalmentPlans.nextInstalmentDueAt?.toFormat(
                                    'dd MMM yyyy'
                                  ) ?? 'No payments due'}
                                </RadioGroup.Label>
                              </div>
                              <RadioGroup.Description
                                as="span"
                                className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center flex items-center"
                              >
                                <span
                                  className={classNames(
                                    checked ? 'text-blue-900' : 'text-gray-900',
                                    'font-medium'
                                  )}
                                >
                                  <NumberFormat
                                    value={formatToDollars(
                                      instalmentPlans.amountPaid
                                    )}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalSeparator={'.'}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={'$'}
                                  />{' '}
                                  /{' '}
                                  <NumberFormat
                                    value={formatToDollars(
                                      instalmentPlans.amount
                                    )}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalSeparator={'.'}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={'$'}
                                  />
                                </span>
                              </RadioGroup.Description>
                              <RadioGroup.Description
                                as="span"
                                className="ml-6 pl-1 mt-1 flex text-sm text-gray-900 items-center"
                              >
                                {instalmentPlans.status === 'pending' && (
                                  <span className="px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                                    Pending
                                  </span>
                                )}
                                {instalmentPlans.status === 'processing' && (
                                  <span className="px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                                    Processing
                                  </span>
                                )}
                                {instalmentPlans.status === 'completed' && (
                                  <span className="px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Completed
                                  </span>
                                )}
                                {instalmentPlans.status === 'on_track' && (
                                  <span className="px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    On Track
                                  </span>
                                )}
                                {instalmentPlans.status === 'overdue' && (
                                  <span className="px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                    Overdue
                                  </span>
                                )}
                                {instalmentPlans.status === 'cancelled' && (
                                  <TooltipMessage
                                    tooltipText={
                                      instalmentPlans.cancelledReason
                                    }
                                    tooltipBgColorAndHue="gray-50"
                                    tooltipTextColorAndHue="yellow-800"
                                    showTooltip={showCancelTooltip}
                                  >
                                    <span className="px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                      Cancelled
                                      {showCancelTooltip && (
                                        <QuestionMarkCircleIcon className="h-4 w-4 ml-2 text-yellow-800 relative top-0.5" />
                                      )}
                                    </span>
                                  </TooltipMessage>
                                )}
                              </RadioGroup.Description>
                            </>
                          )}
                        </RadioGroup.Option>
                      );
                    })}
                  </div>
                </RadioGroup>
              </section>
            </div>
          </div>
        </section>
      </div>

      {focusedPlanId === null && (
        <section
          aria-labelledby="timeline-title"
          className="lg:col-start-3 lg:col-span-2"
        >
          <div className="bg-white shadow sm:rounded-lg ">
            <div className="flex rounded-tl-md rounded-tr-md align-center justify-between bg-slate-50 border-slate-200 z-10 px-4 py-5 sm:px-6 relative border ">
              <h2
                id="timeline-title"
                className="text-lg font-medium text-gray-900"
              >
                None selected
              </h2>
              <h4
                id="timeline-title"
                className="text-md font-medium text-gray-500"
              >
                No payment plan selected
              </h4>
            </div>
          </div>
        </section>
      )}
      {activePlan && <InstalmentPlan instalmentPlan={activePlan} />}
    </div>
  );
};
