import {Result} from '@unruly-software/result';
import axios from 'axios';
import {authClient, client} from 'lib/graphql/apolloClient';
import {apiURL} from 'lib/url';
import {errs, getBillerConfigById} from 'payble-shared';
import {api} from '../api';
import {reset} from './useAuthState';

export const lookup = Result.wrapAsync(async (email: string) => {
  try {
    const response = await axios.request({
      method: 'POST',
      url: `${apiURL}/lookup`,
      data: {email},
      headers: {
        'content-type': 'application/json',
      },
    });

    return response.data as
      | {
          method: 'PASSWORD';
        }
      | {
          method: 'SAML';
          identityProviderId: string;
        };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw errs.fromHTTP(e.response?.data);
    }
    throw e;
  }
});

export const loadSession = Result.wrapAsync(async () => {
  const response = await api.request('getSession', {}).get();

  const {adminId, appConfig, email, expiresAt} = response;
  return {
    expiresAt,
    adminId,
    email,
    billerName: appConfig.biller.name,
    billerSlug: appConfig.biller.slug,
    billerId: appConfig.biller.billerId,
    appConfig,
    billerConfig: getBillerConfigById(appConfig.biller.billerId),
  };
}).withRetries({
  maxRetries: 3,
  initialDelayMS: 1000,
  shouldRetry: ({error}) => {
    if (error instanceof errs.AuthenticationError) {
      return false;
    }
    return true;
  },
});

type LoginOptions = {
  username: string;
  password: string;
};

export const logout = Result.wrapAsync(async () => {
  try {
    await axios.request({
      method: 'POST',
      url: `${apiURL}/logout`,
      headers: {
        'content-type': 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw errs.fromHTTP(e.response?.data);
    }
    throw e;
  }
}).tapEither(clear);

export const login = Result.wrapAsync(
  async ({username, password}: LoginOptions) => {
    try {
      await axios.request({
        method: 'POST',
        url: `${apiURL}/login`,
        headers: {'content-type': 'application/json'},
        data: {
          email: username,
          password,
        },
      });

      return {};
    } catch (e) {
      if (axios.isAxiosError(e)) {
        throw errs.fromHTTP(e.response?.data);
      }
      throw e;
    }
  }
);

export function encounteredAuthError(__: errs.AuthenticationError) {
  // Pushing this to the next tick seems to resolve unmounted component errors
  setTimeout(() => {
    authClient.cache.reset({discardWatches: true}).finally(() => {
      reset();
    });
    clear();
  }, 0);
}

export async function clear() {
  await client.cache.reset({discardWatches: true});
}
